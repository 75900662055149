(function () {
    function renderButton() {
        if (typeof gapi === 'undefined') {
            return;
        }

        gapi.signin2.render('google-signin2', {
            'scope': 'profile email',
            'height': 56,
            'longtitle': true,
            'onSuccess': onGoogleSignIn
        });
    }

    renderButton();

    function onGoogleSignIn(googleUser) {
        var profile = googleUser.getBasicProfile();
        var idToken = googleUser.getAuthResponse().id_token;
        var email = profile.getEmail();
        var firstname = profile.getGivenName();
        var lastname = profile.getFamilyName();
        if (idToken && email) {
            jQuery.ajax({
                url: "externallogin",
                type: "POST",
                data: {
                    email: email,
                    idToken: idToken,
                    firstname: firstname,
                    lastname: lastname,
                    login_type: 'google',
                    from: $('#google-signin2').data('from')
                },
                statusCode: {
                    400: function (error) {
                        console.log(error.responseText);
                        googleSignOut();
                    },
                    200: function (response) {
                        googleSignOut();
                        window.location.replace("//" + response);
                    }

                }
            });
        } else {
            googleSignOut();
        }
    }

    function googleSignOut() {
        if (typeof gapi === 'undefined') {
            return;
        }

        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () { });
    }
})(jQuery);

function checkLoginState(from) {
    FB.login(function (response) {
        if (response.status == "connected") {
            onFacebookSignIn(from);
        }
    }, { scope: 'email' });
}
function onFacebookSignIn(from) {
    FB.api('/me', { locale: 'en_US', fields: 'id, first_name, last_name, email' }, function (response) {
        var facebookId = response.id;
        var email = response.email;
        var firstname = response.first_name;
        var lastname = response.last_name;
        if (facebookId && email) {
            jQuery.ajax({
                url: "externallogin",
                type: "POST",
                data: { email: email, externalId: facebookId, firstname: firstname, lastname: lastname, login_type: 'facebook', from: from },
                statusCode: {
                    404: function () {
                        facebookLogOut();

                    },
                    400: function () {
                        facebookLogOut();
                    },
                    200: function (response) {
                        facebookLogOut();
                        window.location.replace("//" + response);
                    }

                }
            });
        } else {
            facebookLogOut();
        }
    });
}
function facebookLogOut() {
    FB.getLoginStatus(function (response) {
        if (response.status === 'connected') {
            FB.logout(function (response) { });
        }
    });
}