var scripts = document.getElementsByTagName("script");
var secureRandom = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

for (let script of scripts) {
    script.setAttribute("nonce", secureRandom);
}

jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        if (ns.includes("noPreventDefault")) {
            this.addEventListener("touchstart", handle, { passive: false });
        } else {
            this.addEventListener("touchstart", handle, { passive: true });
        }
    }
};

jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        if (ns.includes("noPreventDefault")) {
            this.addEventListener("touchmove", handle, { passive: false });
        } else {
            this.addEventListener("touchmove", handle, { passive: true });
        }
    }
};

document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

$('.banner-img').paroller();

collapsableResponsive();

$(window).resize(function () {
    collapsableResponsive();
});

function collapsableResponsive() {
    if ($(window).width() <= 750) {
        $(".footer-column h2").unbind("click");
        $(".footer-column h2 .footer-icon-expand").remove();
        $(".footer-column h2").append("<span class='footer-icon-expand' aria-hidden='true'></span>");
        $(".footer-column h2").on("click", function () {
            if ($(this).closest(".footer-column").hasClass("open")) {
                $(this).find('.footer-icon-expand').removeClass('footer-icon-collapse');
                $(this).closest(".footer-column").removeClass("open");
            } else {
                $(".footer-column").removeClass("open");
                $(".footer-column").find('.footer-icon-expand').removeClass('footer-icon-collapse');
                $(this).find('.footer-icon-expand').addClass('footer-icon-collapse');
                $(this).closest(".footer-column").addClass("open");
            }
        });

    } else {
        $(".footer-column h2").css("cursor", "default").unbind("click").removeClass("open").find(".footer-icon-expand").remove();
    }
}

function checkWidth() {
    if ($(window).width() < 1008) {
        $(".navi li ul").hide();
    } else {
        $(".navi li ul").attr('style', '');
    }
}

checkWidth();

$(".navi li").each(function () {
    var li = $(this);
    if (li.find('ul li').length > 0) {
        li.append("<a href='#' rel='nofollow noopener' class='expand' onclick='return false;' aria-label='{{ _(Expand menu) }}'><i class='icon-expand' aria-hidden='true'></i></a>");
        li.append("<a href='#' rel='nofollow noopener' class='colapse' onclick='return false;' aria-label='{{ _(Collapse menu) }}'><i class='icon-colapse' aria-hidden='true'></i></a>");
    }
});

$('body').on('click', 'a.expand', function () {
    $(this).hide().next().show();
    $(this).parent().siblings().find('.subnavi-container').slideUp();
    $('.random-product').hide();
    $('.sub-navigation').show();
    $('.sub-navigation ul').show();
    $(this).parent().siblings().find('a.expand').show();
    $(this).parent().siblings().find('a.colapse').hide();
    $(this).parent().find('.subnavi-container').addClass('expanded').stop(true, true).slideDown();
});

$('body').on('click', 'a.colapse', function () {
    $(this).parent().find('.subnavi-container').removeClass('expanded').stop(true, true).slideUp();
    $(this).parent().find('a.colapse').hide();
    $(this).parent().find('a.expand').show();
});

/*$(document).ready(function () {
    if ($(window).width() > 992) {
        $(window).on("scroll", function () {
            var fromTop = $(document).scrollTop();
            $(".header-container").toggleClass("sticky", (fromTop > 10));
        });
    } else {
        return;
    }
});*/

$(window).on('scroll', function () {
    if ($(this).scrollTop() > 1) {
        $('.header-container').addClass("sticky");
    } else {
        $('.header-container').removeClass("sticky");
    }
});

$(function () {
    var lastScrollTop = 0, delta = 15;
    $(window).scroll(function (event) {
        if ($(window).width() < 992) {
            var st = $(this).scrollTop();

            if (Math.abs(lastScrollTop - st) <= delta)
                return;
            if ((st > lastScrollTop) && (lastScrollTop > 0)) {
                $("header").css("top", "-127px");

            } else {
                $("header").css("top", "0px");
            }
            lastScrollTop = st;
        }
    });
});

$(".hamburger").on('click', function () {
    $(".hamburger").toggleClass("is-active");
    $(".navigation").toggleClass("is-active");
    $("body").toggleClass("menu-expanded");
});

$(".open-filters").on('click', function () {
    $(".product-filters").fadeIn().slideDown();
    $("body").addClass("filters-opened");
});

$(".product-filters .icon-down").on('click', function () {
    $(".product-filters").fadeOut().slideUp();
    $("body").removeClass("filters-opened");
});

function plusMinus() {
    $('.btn-plus, .btn-minus').off("click").on('click', function (e) {
        var isNegative = $(e.target).closest('.btn-minus').is('.btn-minus');
        var input = $(e.target).closest('.quantity').find('input');
        if (input.is('input')) {
            var stock = $(this).data("stock");
            var productId = $(this).data("id");

            if (!isNegative && stock <= input.val()) {
                if (productId === undefined) {
                    $('.stock-alert').addClass('max-stock-limit');
                }
                else {
                    $('.stock-alert.' + productId).addClass('max-stock-limit');
                }
                return;

            } else {
                $('.stock-alert').removeClass('max-stock-limit');
            }
            input[0][isNegative ? 'stepDown' : 'stepUp']();
        }
    });
}
plusMinus();

$(document).on('shown.bs.dropdown', function (event) {
    var dropdown = $(event.target);
    setTimeout(function () {
        dropdown.find('.dropdown-menu a:first-child').focus();
    }, 10);
});

$(document).on('hidden.bs.dropdown', function (event) {
    var dropdown = $(event.target);
    dropdown.find('.dropdown-toggle').focus();
});

$(window).scroll(function () {
    var counter = $("#counter");
    if ($(counter).length > 0) {
        var counterVal = counter.find(".counter-value");
        $(counterVal).each(function () {
            var $this = $(this),
                countTo = $this.data('count');
            $({
                countNum: $this.text()
            }).animate({
                countNum: countTo
            },
                {
                    duration: 2000,
                    easing: 'swing',
                    step: function () {
                        $this.text(Math.floor(this.countNum));
                    },
                    complete: function () {
                        $this.text(this.countNum);
                    }
                });
        });
    }
});

var $salePopup = $("#promo-code");
var $close = $salePopup.find(".close");

$(document).ready(function () {
    setTimeout(function () {
        $salePopup.addClass("show");
    }, 2000);
});

$close.on("click", function () {
    "use strict";
    $salePopup.removeClass("show");
});

$(document).ready(function () {
    "use strict";
    var $filterSection = $("#filter-section");
    var $moreFiltersBtn = $(".product-filters").find(".btn-more-filters");
    var $moreFiltersSpan = $moreFiltersBtn.find("span");
    var $productSort = $(".product-filters").find(".product-sort");

    $moreFiltersSpan.text("Vsi filtri");

    $moreFiltersBtn.on("click", function (e) {
        if ($productSort.hasClass("hidden")) {
            $filterSection.toggleClass("expanded");
            var vsiText = $moreFiltersSpan.text();
            var manjText = $moreFiltersSpan.data('text');
            $moreFiltersSpan.text(manjText).data('text', vsiText);
            $productSort.toggleClass("show");
        }
    });

    var $filterSectionMobile = $filterSection.find(".mobile");
    var $filtersShow = $filterSectionMobile.find("button");
    var $filterMobileMenu = $filterSectionMobile.find(".menu");
    var $filterMobileMenuClose = $filterMobileMenu.find(".close-menu");

    $filtersShow.on("click", function () {
        $filterMobileMenu.addClass("active");
    });

    $filterMobileMenuClose.on("click", function () {
        $filterMobileMenu.removeClass("active");
    });
});

$('.slider-logos').slick({
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 2,
    rows: 0,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    centerMode: true,
    mobileFirst: true,
    focusOnSelect: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 750,
            settings: {
                slidesToShow: 3,
            }
        }
    ]
});

$('.big-image').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.small-images',
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                dots: false
            }
        }
    ]
});

$('.small-images').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.big-image',
    dots: false,
    arrows: false,
    focusOnSelect: true,
    vertical: false,
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
                vertical: true
            }
        }
    ]
});

$('.small-images .slick-slide').removeClass('slick-active');

//set active class to first thumbnail slides
$('.small-images .slick-slide').eq(0).addClass('slick-active');

// On before slide change match active thumbnail to current slide
$('.big-image').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    var mySlideNumber = nextSlide;
    $('.small-images .slick-slide').removeClass('slick-active');
    $('.small-images .slick-slide').eq(mySlideNumber).addClass('slick-active');
});

var loader = {
    init: function (triggerSelector, targetSelector, callbackBefore, callbackAfter) {
        $("body").on('click', triggerSelector, function () {

            window.history.pushState(null, null, $(this).attr('href'));

            if (callbackBefore !== undefined) {
                callbackBefore($(this));
            }

            loader.load($(this).attr('href'), targetSelector, callbackAfter);

            return false;
        });
    },
    load: function (url, targetSelector, callbackAfter) {
        $(".list-loader").show();

        url += url.includes('?') ? '&ajax' : '?ajax';

        $.get(url).then(function (response) {
            $(targetSelector).html(response);
        });
    }
};

var relatedPlaceholder = $(".related-products-placeholder");
if (relatedPlaceholder.length > 0) {
    relatedPlaceholder.prepend('<div style="display:block !important;" class="list-loader"><div class="inner"><div></div><div></div><div></div></div></div>');

    $.get('/product/related/' + relatedPlaceholder.data('id'), function (response) {
        relatedPlaceholder.html(response);
        $('.related-products-placeholder').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: false,
            rows: 0,
            infinite: true,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        })
            .on('setPosition', function (event, slick) {
                slick.$slides.css('height', slick.$slideTrack.height() + 'px');
            });

        new Cart($(".side-cart-placeholder"), 'side');
        plusMinus();
    });
}

var productList = {
    loadRequest: null,
    load: function (url) {
        $(".products-list-placeholder").prepend('<div class="list-loader"><div class="inner"><div></div><div></div><div></div><div></div></div></div>');

        window.history.pushState(null, null, url);

        if (productList.loadRequest != null) {
            productList.loadRequest.abort();
        }

        url = url.split('#')[0] + (url.includes('?') ? '&ajax' : '?ajax');

        productList.loadRequest = $.get(url, function (response) {
            $(".products-list-placeholder").html(response);

            if ($(".products-list-placeholder .product.contains_alcohol").length > 0) {
                $("body").addClass("contains_alcohol");
            }

            $(".pagination-page").on('click', function () {
                productList.load($(this).attr('href'));
                window.scrollTo(0, 0);
                return false;
            });

            $(".pl-data-url").on('click', function () {
                productList.load($(this).data('url'));
                window.scrollTo(0, 0);
                return false;
            });

            var btnToggle = $(".product-sorting").find(".options");
            var selectSort = $(".options-menu").find(".active");
            btnToggle.text(selectSort.text());

            var btnToggle = $(".product-sorting").find(".paginate");
            var selectSort = $(".paginate-menu").find(".active");
            btnToggle.text(selectSort.text());

            new Cart($(".side-cart-placeholder"), 'side');
            plusMinus();
        });
    },
    initFilters: function () {
        var found = location.href.match(/filters=(.*?)(?:\&.*)*$/);
        if (found != null) {
            var filters = found[1].split(';');

            for (var i = 0; i < filters.length; i++) {
                $(".product-filters #dropdown-menu input[value='" + decodeURI(filters[i]) + "']").prop('checked', true);
            }
        }
        $("#filter-section .form-check > input").on('change', function () {
            productList.changeFilter($(this).val(), $(this).is(':checked'));
        });
    },
    changeFilter: function (value, checked) {
        value = encodeURI(value);
        var filters = [];

        var found = location.href.match(/filters=(.*?)(?:\&.*)*$/);
        if (found != null) {
            filters = found[1].split(';');
        }

        if (checked && filters.indexOf(value) == -1) {
            filters.push(value);
        }

        if (!checked && filters.indexOf(value) > -1) {
            filters.splice(filters.indexOf(value), 1);
        }

        var url = location.href;
        var urlFilters = filters.length > 0 ? 'filters=' + filters.join(';') : '';

        if (found) {
            url = url.replace('filters=' + found[1], urlFilters);
        } else if (urlFilters != '') {
            url += (url.indexOf('?') > -1 ? '&' : '?') + urlFilters;
        }

        productList.load(url.endsWith('?') ? url.substr(0, url.length - 1) : url);
    }
};

if ($(".products-list-placeholder").length > 0) {
    productList.load(location.href);
}

if ($(".product-filters").length > 0) {
    productList.initFilters();
}

function containsAlcohol() {
    if ($("body").hasClass("contains_alcohol") && getCookie("alcohol_confirmed") == null) {
        $('#alcohol-modal').modal('show');

        $("#alcohol-modal .confirm").on("click", function () {
            document.cookie = "alcohol_confirmed=true; max-age=" + 30 * 24 * 60 * 60;
            $('#alcohol-modal').modal('hide');
        });

        $("#alcohol-modal .deny").on("click", function () {
            document.location.href = "/";
        });
    }
}

containsAlcohol();
//check if contains_alcohol class was added to body
var mut = new MutationObserver(function (mutations, mut) {
    if (mutations[0].attributeName) {
        containsAlcohol();
    }
});
mut.observe(document.querySelector("body"), {
    'attributes': true
});

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
}

var $referralInput = $(".referral").find("input");
var $copy = $(".referral").find(".copy-btn");

$copy.on("click", function () {
    $referralInput.select();
    navigator.clipboard.writeText($referralInput.val()).then(function () {
        console.log("Copied!");
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
});

(function () {
    var searchToggleBtn = $(".mobile-search-toggle");
    var searchContainer = $(".search-container");
    var form = searchContainer.find("form");
    var input = searchContainer.find("input");
    var tooltip = searchContainer.find(".invalid-tooltip");
    var regex = new RegExp("\\/|\\-|\\+");

    searchToggleBtn.on("click", function () {
        searchContainer.slideToggle();
    });

    input.on("input", function () {
        if (regex.test(input.val()) == true) {
            tooltip.show();
        } else {
            tooltip.hide();
        }
    });

    form.on("submit", function () {
        if (regex.test(input.val()) == true) {
            return false;
        }
    });
})(jQuery);

/*document.addEventListener('copy', async (e) => {
    e.preventDefault();
    try {
        let clipboardItems = [];
        for (const item of e.clipboardData.items) {
            clipboardItems.push(
                new ClipboardItem({
                    [item.type]: item,
                })
            );
            await navigator.clipboard.write(clipboardItems);
            console.log('Image copied.');
        }
    } catch (err) {
        console.error(err.name, err.message);
    }
});*/
